import { TableActionPopover } from 'BreetComponents';
import type { RoutParamsType } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import {
	openModal,
	selectGetSingleUser,
	useAppDispatch,
	useAppSelector,
	useFreezeUserMutation,
	useLazyGetUserFinanceSummaryQuery,
	useRecheckUserAMLMutation,
	useResendUserEmailVerificationMutation,
	useRestoreSingleUserMutation,
	useUnfreezeUserMutation,
	useVerifyUserEmailMutation,
} from 'BreetRedux';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UserDetailsAction = () => {
	const { userId } = useParams<RoutParamsType>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { data: userData } = useAppSelector(selectGetSingleUser(userId));
	const [fetchFinanceSUmmary, { isFetching: isLoadingUserSummary }] = useLazyGetUserFinanceSummaryQuery();
	const [freezeUser, { isLoading: isFreezingUser }] = useFreezeUserMutation();
	const [unfreezeUser, { isLoading: isUnfreezingUser }] = useUnfreezeUserMutation();
	const [verifyEmail, { isLoading: isVerifyingEmail }] = useVerifyUserEmailMutation();
	const [resendEmailVerification, { isLoading: isResendingEmailVerification }] = useResendUserEmailVerificationMutation();
	const [restoreSingleUser, { isLoading: isRestoringUser }] = useRestoreSingleUserMutation();
	const [recheckUserAML, { isLoading: usCheckingUserAML }] = useRecheckUserAMLMutation();

	const isUserFreezed = !!userData?.data?.freeze;
	const isUserDeleted = !!userData?.data?.deletedAt;

	const handleEmailVerification = useCallback(() => {
		if (!userId) return;

		verifyEmail(userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [userId, verifyEmail]);

	const handleResendEmailVerification = useCallback(() => {
		if (!userId) return;

		resendEmailVerification(userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [userId, resendEmailVerification]);

	const handleRemoveProfileNote = useCallback(() => {
		dispatch(
			openModal({
				id: 'removeProfileNote',
				header: 'Remove Profile Note',
				metadata: { note: userData?.data?.tag.note, userId },
			})
		);
	}, [dispatch, userData?.data?.tag.note, userId]);

	const handleAddProfileNote = useCallback(() => {
		dispatch(
			openModal({
				id: 'addProfileNote',
				header: 'Adding Profile Note',
				metadata: { note: userData?.data?.tag.note, userId },
			})
		);
	}, [dispatch, userData?.data?.tag.note, userId]);

	const handleFreezeOrUnfreezeUser = useCallback(() => {
		if (!userId) return;

		if (isUserFreezed) {
			unfreezeUser(userId)
				.unwrap()
				.then((resp) => {
					spawnAppToast({ message: resp.message, type: 'success' });
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		} else {
			freezeUser(userId)
				.unwrap()
				.then((resp) => {
					spawnAppToast({ message: resp.message, type: 'success' });
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		}
	}, [freezeUser, isUserFreezed, unfreezeUser, userId]);

	const handleAddRemoveUserPoints = useCallback(
		(isAdding?: boolean) => () => {
			fetchFinanceSUmmary(userId)
				.unwrap()
				.then((resp) => {
					dispatch(
						openModal({
							id: 'addRemoveUserPointBalance',
							header: `${isAdding ? 'Adding to' : 'Removing from'} Points Balance`,
							metadata: { rewardPoints: resp.data?.referralInfo, isAdding, userId },
						})
					);
				})
				.catch((err: unknown) => {
					console.error(err);
				});
		},
		[dispatch, fetchFinanceSUmmary, userId]
	);

	const handleDeleteOrRestoreUser = useCallback(() => {
		if (isUserDeleted) {
			restoreSingleUser(userId)
				.unwrap()
				.then((resp) => {
					spawnAppToast({ message: resp.message, type: 'success' });
				})
				.catch((err: unknown) => {
					spawnAppToast({ dataMsg: err, type: 'error' });
				});
		} else {
			dispatch(
				openModal({
					id: 'deleteSingleUser',
					header: 'Deleting User',
					metadata: { userId },
				})
			);
		}
	}, [dispatch, isUserDeleted, restoreSingleUser, userId]);

	const handleEditUser = useCallback(() => {
		navigate(`/user-management/users/${userId}/edit`);
	}, [navigate, userId]);

	const handleAddOrRemoveFiatBalance = useCallback(
		(isAddingFiat?: boolean) => () => {
			dispatch(
				openModal({
					id: 'addRemoveFiatBalance',
					header: `${isAddingFiat ? 'Adding to' : 'Removing from'} Fiat Balance`,
					metadata: {
						userId,
						currency: userData?.data?.wallet?.currency,
						balance: userData?.data?.wallet?.balance,
						isAddingFiat,
					},
				})
			);
		},
		[dispatch, userData?.data?.wallet, userId]
	);

	const handleRecheckUserAML = useCallback(() => {
		if (!userId) return;

		recheckUserAML(userId)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [recheckUserAML, userId]);

	return (
		<div className='userDetails_action'>
			<TableActionPopover
				triggerVariant='gray'
				actionItems={[
					{ title: 'Mark Email Verified', isLoading: isVerifyingEmail, onClick: handleEmailVerification },
					{
						title: 'Resend Verification Code Mail',
						isLoading: isResendingEmailVerification,
						onClick: handleResendEmailVerification,
					},
					{ title: 'Add Profile Note', onClick: handleAddProfileNote },
					{ title: 'Remove Profile Note', onClick: handleRemoveProfileNote },
					{
						title: isUserFreezed ? 'Unfreeze Account' : 'Freeze Account',
						isLoading: isFreezingUser || isUnfreezingUser,
						onClick: handleFreezeOrUnfreezeUser,
					},
					{ title: 'Credit Manual Reward Points', isLoading: isLoadingUserSummary, onClick: handleAddRemoveUserPoints(true) },
					{ title: 'Remove from Points Balance', isLoading: isLoadingUserSummary, onClick: handleAddRemoveUserPoints() },
					{ title: 'Recheck User AML With Dojah', isLoading: usCheckingUserAML, onClick: handleRecheckUserAML },
					{ title: 'Add to FIAT Balance', onClick: handleAddOrRemoveFiatBalance(true) },
					{ title: 'Remove from FIAT Balance', onClick: handleAddOrRemoveFiatBalance() },
					{ title: 'Edit User', onClick: handleEditUser },
					{
						title: isUserDeleted ? 'Restore User' : 'Delete User',
						isLoading: isRestoringUser,
						onClick: handleDeleteOrRestoreUser,
					},
				]}
			/>
		</div>
	);
};

export default UserDetailsAction;
