import type { RouteObject } from 'react-router-dom';

const siteMgtRoutes: RouteObject[] = [
	{
		path: 'site-management',
		children: [
			{
				path: 'wallet-and-withdrawal',
				lazy: () => import('../features/site-management/wallet-and-withdrawal'),
			},
			{
				path: 'crypto',
				lazy: () => import('../features/site-management/crypto'),
			},
			{
				path: 'swap-crypto',
				lazy: () => import('../features/site-management/swap-crypto'),
			},
			{
				path: 'bills',
				lazy: () => import('../features/site-management/bills'),
			},
			{
				path: 'invoice',
				lazy: () => import('../features/site-management/invoice'),
			},
			{
				path: 'reward',
				lazy: () => import('../features/site-management/reward'),
			},
			{
				path: 'referral',
				lazy: () => import('../features/site-management/referral'),
			},
			{
				path: 'kyc',
				lazy: () => import('../features/site-management/kyc'),
			},
			{
				path: 'cms',
				lazy: () => import('../features/site-management/cms'),
			},
		],
	},
];

export default siteMgtRoutes;
