import { Modal, ToastWrapper } from 'BreetComponents';
import { manuallyTriggeredModals, modalContent } from 'BreetConfig';
import { selectModals, selectThemeMode, useAppSelector } from 'BreetRedux';
import { useLayoutEffect, useMemo } from 'react';

export const BaseLayout = ({ children }: React.PropsWithChildren) => {
	const themeMode = useAppSelector(selectThemeMode);
	const modals = useAppSelector(selectModals);

	const isDarkTheme = useMemo(() => themeMode === 'dark', [themeMode]);

	useLayoutEffect(() => {
		const bodyElement = document.body;
		if (isDarkTheme) bodyElement.setAttribute('data-theme-mode', 'dark');
		if (!isDarkTheme) bodyElement.removeAttribute('data-theme-mode');
	}, [isDarkTheme]);

	return (
		<div className='bt_layout_base'>
			{children}
			<ToastWrapper />
			{modals
				.filter((modal) => !manuallyTriggeredModals.includes(modal.id))
				.map((modal) => (
					<Modal
						key={modal.id}
						modalId={modal.id}
						header={modal.header}
					>
						{modalContent[modal.id]}
					</Modal>
				))}
		</div>
	);
};
