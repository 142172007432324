import type { TableHeaderFilterListType } from 'BreetComponents';
import type { ShortCurrencyType } from 'BreetHelpers';
import { clientChannelOptions } from 'BreetHelpers';
import * as yup from 'yup';

import type { ButtonVariant } from '@/components/Button/Button.helper';

const withdrawalReqStatusOptions = [
	{
		id: 'pending',
		label: 'Pending',
		value: 'pending',
	},
	{
		id: 'processing',
		label: 'Processing',
		value: 'processing',
	},
	{
		id: 'completed',
		label: 'Completed',
		value: 'completed',
	},
	{
		id: 'rejected',
		label: 'Rejected',
		value: 'rejected',
	},
	{
		id: 'reversed',
		label: 'Reversed',
		value: 'reversed',
	},
];

export const withdrawalReqFilterData: TableHeaderFilterListType[] = [
	{
		id: 'status',
		label: 'Status',
		itemOptions: withdrawalReqStatusOptions,
	},
	{
		id: 'channel',
		label: 'Platform',
		itemOptions: clientChannelOptions,
	},
];

export const withdrawalApprovalOptions = {
	approve: 'Approve Request',
	reject: 'Rejecting Request',
} as const;

export type WithdrawalApprovalOptionKeyType = keyof typeof withdrawalApprovalOptions;

export const ApproveOrRejectWithdrawalSchema = yup.object({
	reason: yup.string(),
});

export type ApproveOrRejectWithdrawalSchemaType = yup.InferType<typeof ApproveOrRejectWithdrawalSchema>;
export type ApproveOrRejectWithdrawalFormKeys = keyof ApproveOrRejectWithdrawalSchemaType;

export interface ApproveOrRejectWithdrawalActionPopoverProps {
	triggerVariant?: ButtonVariant;
	currency: ShortCurrencyType;
	dataIds: string[];
	isDetailsPage?: boolean;
}
