import { Text } from 'BreetComponents';
import type { RoutParamsType } from 'BreetConfig';
import { allRouteListItems, sanitizeRouteParams } from 'BreetConfig';
import { selectUser, useAppSelector } from 'BreetRedux';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { UserIcon } from '@/assets/icons';

import ThemeToggle from './ThemeToggle';

const PageTopHeader = () => {
	const { pathname } = useLocation();
	const params = useParams<RoutParamsType>();
	const { data: currentUser } = useAppSelector(selectUser);

	const currentRouteObject = useMemo(
		() => allRouteListItems.find((route) => sanitizeRouteParams(route.linkRef, params) === pathname),
		[params, pathname]
	);

	return (
		<div className='page_topHeader'>
			<div className='page_topHeader_ltr'>
				<Text variant='body_head_large'>
					{sanitizeRouteParams(currentRouteObject?.pageTitle ?? currentRouteObject?.linkTitle ?? '', params)}
				</Text>
			</div>
			<div className='page_topHeader_rtl align-flex-center'>
				<ThemeToggle />
				<div className='userProfile align-flex-center'>
					<UserIcon />
					<Text variant='body_small_bold'>{currentUser?.data?.username}</Text>
				</div>
			</div>
		</div>
	);
};

export default PageTopHeader;
