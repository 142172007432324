import type { IApiResponse, IApiResponsePaginated, IUserCryptoWallet, IUserFiatWallet } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const walletsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUserWallets: builder.query<
			IApiResponsePaginated<(IUserFiatWallet | IUserCryptoWallet)[]>,
			Record<string, string | boolean | number | undefined>
		>({
			query: ({ userId, ...params }) => ({ url: `/admin/users/user/${userId}/wallets`, params }),
			providesTags: ['GetUserWallets'],
		}),
		getWalletDetails: builder.query<IApiResponse<IUserFiatWallet | IUserCryptoWallet>, string | undefined>({
			query: (cryptoWalletId) => ({ url: `/admin/users/wallets/wallet/${cryptoWalletId}` }),
			providesTags: ['GetWalletDetails'],
		}),
		deactivateWallet: builder.mutation<IApiResponse, string | undefined>({
			query: (cryptoWalletId) => ({ method: 'PUT', url: `/admin/users/wallets/wallet/${cryptoWalletId}` }),
			invalidatesTags: ['GetWalletDetails'],
		}),
		updateUserFiatBalance: builder.mutation<IApiResponse, Record<string, string | boolean | number>>({
			query: ({ userId, amount, currency, isAddingFiat, reason }) => ({
				method: 'PUT',
				url: `/admin/users/user/${userId}/edits/${isAddingFiat ? 'credit' : 'debit'}`,
				body: { amount, currency, reason },
			}),
			invalidatesTags: ['SingleUser', 'GetUserWallets'],
		}),
	}),
});

export const { useGetUserWalletsQuery, useGetWalletDetailsQuery, useDeactivateWalletMutation, useUpdateUserFiatBalanceMutation } =
	walletsApiSlice;
