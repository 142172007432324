import type { UserCountryType, UserDeviceType } from '../types';

const TIME_FORMAT = 'hh:m a';
const DATE_FORMAT = 'MMM, dd yyyy';
const FULL_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss zzz';
const FULL_DATE_TIME_FORMAT2 = 'MMMM d yyyy, h:mm aa';
const CALENDAR_DATE_TIME_FORMAT = 'MMMM d, yyyy h:mm aa';

export const dateConst = { TIME_FORMAT, DATE_FORMAT, FULL_DATE_TIME_FORMAT, FULL_DATE_TIME_FORMAT2, CALENDAR_DATE_TIME_FORMAT };

export const clientChannelOptions: {
	id: UserDeviceType;
	label: string;
	value: UserDeviceType;
}[] = [
	{
		id: 'web',
		label: 'Website',
		value: 'web',
	},
	{
		id: 'ios',
		label: 'Mobile App (iOS)',
		value: 'ios',
	},
	{
		id: 'android',
		label: 'Mobile App (Android)',
		value: 'android',
	},
];

export const clientCountryObj: Record<
	UserCountryType,
	{ country: string; currencyValue: string; currencyName: string; currencySign: string }
> = {
	nigeria: {
		country: 'Nigeria',
		currencyValue: 'NGN',
		currencyName: 'Naira',
		currencySign: '₦',
	},
	ghana: {
		country: 'Ghana',
		currencyValue: 'GHS',
		currencyName: 'Cedis',
		currencySign: '₵',
	},
};
