import { store, usersApiSlice } from 'BreetRedux';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import { BaseLayout, NonProtectedLayout, ProtectedLayout } from '@/layout';

import accessMgtRoutes from './accessMgt.routes';
import reportRoutes from './reports.routes';
import siteMgtRoutes from './siteMgt.routes';
import userMgtRoutes from './userMgt.routes';
import withdrawalRoutes from './withdrawals.routes';

export const routes: RouteObject[] = [
	{
		path: '/',
		element: (
			<BaseLayout>
				<Outlet />
			</BaseLayout>
		),
		children: [
			{
				index: true,
				element: (
					<Navigate
						replace
						to='/dashboard'
					/>
				),
			},
			{
				path: 'app-components',
				lazy: () => import('../features/misc/app-components'),
			},
			{
				id: 'non-protected',
				element: <NonProtectedLayout />,
				loader: () => {
					const { isUserLoggedIn } = store.getState().app;
					if (isUserLoggedIn) {
						window.location.replace('/dashboard');
					}
					return isUserLoggedIn;
				},
				children: [
					{
						index: true,
						lazy: () => import('../features/misc/home'),
					},
					{
						path: 'login',
						lazy: () => import('../features/auth/login'),
					},
				],
			},
			{
				id: 'protected',
				element: <ProtectedLayout />,
				loader: async () => {
					const { isUserLoggedIn } = store.getState().app;
					if (!isUserLoggedIn) {
						window.location.replace('/login');
					}
					const promise = store.dispatch(usersApiSlice.endpoints.getCurretUser.initiate());
					const { data } = await promise;

					if (!data) {
						window.location.replace('/getlost');
					}
					return data ?? null;
				},
				children: [
					{
						path: 'dashboard',
						lazy: () => import('../features/dashboard'),
					},
					...withdrawalRoutes,
					{
						path: 'trades',
						children: [
							{
								index: true,
								lazy: () => import('../features/trades'),
							},
							{
								path: ':txReference',
								lazy: () => import('../features/trades/[txReference]'),
							},
						],
					},
					{
						path: 'swap-crypto',
						children: [
							{
								index: true,
								lazy: () => import('../features/swap-crypto'),
							},
							{
								path: ':txReference',
								lazy: () => import('../features/swap-crypto/[txReference]'),
							},
						],
					},
					...accessMgtRoutes,
					...userMgtRoutes,
					{
						path: 'bills',
						children: [
							{
								index: true,
								lazy: () => import('../features/bills'),
							},
							{
								path: ':txReference',
								lazy: () => import('../features/bills/[txReference]'),
							},
						],
					},
					{
						path: 'invoices',
						children: [
							{
								index: true,
								lazy: () => import('../features/invoices'),
							},
							{
								path: ':txReference',
								lazy: () => import('../features/invoices/[txReference]'),
							},
						],
					},
					...siteMgtRoutes,
					...reportRoutes,
				],
			},
			{
				path: 'getlost',
				lazy: () => import('../features/misc/getlost'),
			},
			{
				path: '*',
				element: (
					<Navigate
						replace
						to='/dashboard'
					/>
				),
			},
		],
	},
];
