import { Button } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useDeleteSingleInvoiceMutation } from 'BreetRedux';
import { useCallback } from 'react';

const DeleteInvoiceModal = () => {
	const modals = useAppSelector(selectModals);
	const [deleteInvoice, { isLoading }] = useDeleteSingleInvoiceMutation();

	const currentModal = modals.find((modal) => modal.id === 'deleteInvoice');
	const modalMetadata = currentModal?.metadata as Record<string, string | string[]> | undefined;

	const handleDeleteInvoice = useCallback(() => {
		if (!modalMetadata?.invoiceId) return;
		deleteInvoice(modalMetadata.invoiceId as string)
			.unwrap()
			.then((resp) => {
				spawnAppToast({ dataMsg: resp, type: 'success' });
				closeModalById('deleteInvoice');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [deleteInvoice, modalMetadata]);

	return (
		<Button
			variant='red'
			onClick={handleDeleteInvoice}
			loading={isLoading}
			size='large'
		>
			Delete
		</Button>
	);
};

export default DeleteInvoiceModal;
