import type { ToastState } from 'BreetRedux';
import { addToast, store } from 'BreetRedux';
import { KeyboardEvent } from 'react';

import { clientChannelOptions } from '../constants';
import type { UserDeviceType } from '../types';

/**
 * Helper function which limits the number of keys pressed in an input field,
 * which also allows the trigger of a callback to be called when the enter key is pressed.
 *
 * @param {*} valueLength - The length of the value typed which defaults to 4
 * @param {*} enterKeyCallback - The enterKeyCallback function to call when when the enter key is pressed
 */
export const inputKeyDownValidator =
	(valueLength = 4, enterKeyCallback?: (value: string) => void) =>
	(e: KeyboardEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		const enterKey = e.which === 13 || e.key === 'Enter';
		const maxValueLength = target.value.length >= valueLength;
		const isNumericKey = /\d/.test(e.key);
		const isControlKey = e.key === 'Backspace' || e.key === 'Delete' || e.ctrlKey;

		// Trigger enterKeyCallback if max value length is reached
		if (maxValueLength && !isControlKey) {
			if (enterKeyCallback) enterKeyCallback(target.value);
		}

		// Prevent input if max value length is reached and key is not control key
		if (maxValueLength && !isControlKey) {
			e.preventDefault();
		}

		// Allow only numeric input, enter key, or control keys (backspace, delete)
		if (!isNumericKey && !enterKey && !isControlKey) {
			e.preventDefault();
		}
	};

export const getLocationFrom = () => {
	const { pathname } = window.location;
	const searchParams = window.location.search;
	return encodeURIComponent(`${pathname}${searchParams}`);
};

const getDataResponseMessage = (dataMsg: unknown) =>
	(dataMsg as { message?: string }).message ??
	(dataMsg as { data?: { message?: string } }).data?.message ??
	'Sorry, an error occurred. Please try again';

/**
 * Reuseable handler to spawn a new toast message
 */
export const spawnAppToast = ({ dataMsg, type, message, ...rest }: { dataMsg?: unknown } & Omit<ToastState, 'id'>) => {
	store.dispatch(
		addToast({
			type,
			message: dataMsg ? getDataResponseMessage(dataMsg) : message,
			...rest,
		})
	);
};

/**
 * Generic helper function that returns array of numbers making it easier to identify
 * elements instead of using array index as keys
 * @param arrayLength -number
 */
export const mapArrayFromLength = (arrayLength: number) => Array.from({ length: arrayLength }).map((_, index) => index + 1);

/**
 * Copies the given text to the clipboard.
 *
 * @param {string} text The text to copy to the clipboard.
 */
export const copyTextToClipboard =
	({ text, id, message }: { text?: string; id?: string; message?: string }) =>
	async () => {
		if (!text) return;
		try {
			await navigator.clipboard.writeText(text);
			store.dispatch(
				addToast({
					type: 'success',
					message: message ?? `Copied ${id ?? 'text'} to clipboard`,
				})
			);
		} catch (error) {
			console.error(error);
			store.dispatch(
				addToast({
					type: 'error',
					message: message ?? `An error occured while trying to copy ${id ?? 'text'} to clipboard`,
				})
			);
		}
	};

export const getChannelOption = (deviceId?: UserDeviceType) => clientChannelOptions.find((channel) => channel.id === deviceId);

/**
 * Checks if there's a change between form's inital/default values and the current form values.
 *
 * @param option.defaultValues - The first value to compare.
 * @param option.currentValues - The second value to compare.
 * @returns  True if there are change(s), false otherwise.
 */
export const formHasChanges = <S, T>({ defaultValues, currentValues }: { defaultValues: S; currentValues: T }) =>
	JSON.stringify(defaultValues) !== JSON.stringify(currentValues);
