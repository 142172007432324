import type { IApiResponse, IBankDetails } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const banksApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUserBanks: builder.query<IApiResponse<IBankDetails[]>, string | undefined>({
			query: (userId) => ({ url: `/admin/payments/user/${userId}/banks` }),
			providesTags: ['GetUserBanks'],
		}),
		getBankDetails: builder.query<IApiResponse<IBankDetails>, string | undefined>({
			query: (accountId) => ({ url: `/admin/payments/bank/${accountId}` }),
			providesTags: ['GetBankDetails'],
		}),
		deleteUserBank: builder.mutation<IApiResponse, string | undefined>({
			query: (accountId) => ({ url: `/admin/bank/${accountId}`, method: 'DELETE' }),
			invalidatesTags: ['GetUserBanks', 'GetBankDetails'],
		}),
	}),
});

export const { useGetUserBanksQuery, useGetBankDetailsQuery, useDeleteUserBankMutation } = banksApiSlice;
