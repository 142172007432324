import { useCallback, useMemo, useState } from 'react';

import { SearchIcon } from '@/assets/icons';

import { SearchInput } from '../Input';
import { Popover } from '../Popover';
import { Text } from '../Text';
import type { ITableHeader } from './Table.helper';
import { getInitialFixedLeftPos, getLastIndexOfFixedColumn } from './Table.helper';

export const TableHeader = <E,>({
	columns,
	withCheckbox,
	checkedItems,
	dataLength,
	isLoading,
	onColumnCheckToggle,
	useCheckBox,
}: ITableHeader<E>) => {
	const [isColumnChecked, setIsColumnChecked] = useState(false);

	const tableDataCheckedState = useMemo(() => {
		if (!dataLength || isLoading) return 'disabled';
		if (!!checkedItems.length && checkedItems.length < dataLength) return 'halfCheck';
		if (checkedItems.length === dataLength) return 'fullCheck';
		return 'uncheck';
	}, [checkedItems.length, dataLength, isLoading]);

	const onTableColumnCheckboxChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (isLoading) return;
			setIsColumnChecked(e.target.checked);
		},
		[isLoading]
	);

	const onTableColumnCheckboxClick = useCallback(
		(e: React.MouseEvent<HTMLTableCellElement>) => {
			if (isLoading) return;
			e.preventDefault();
			onColumnCheckToggle();
		},
		[isLoading, onColumnCheckToggle]
	);

	const handleTableColumnSearch = useCallback(
		(onSearch: (data: Record<string, string>) => void, searchKey: string | number) => (value: string) => {
			onSearch({ [String(searchKey)]: value });
		},
		[]
	);

	return (
		<tr>
			{withCheckbox && (
				<th
					className={`table-head-cell bt_table_checkbox ${tableDataCheckedState}`}
					onClick={onTableColumnCheckboxClick}
				>
					<input
						checked={isColumnChecked}
						onChange={onTableColumnCheckboxChange}
						className='bt_table_checkbox_input'
						id='bt_table_checkbox'
						type='checkbox'
						disabled={!dataLength}
					/>
					<label
						className='bt_table_checkbox_label'
						htmlFor='bt_table_checkbox'
					>
						<span className={`bt_table_checkbox_label_content ${tableDataCheckedState}`}>-</span>
					</label>
				</th>
			)}
			{columns.map((column, columnIndex) => (
				<th
					key={`table-head-cell-${column.key}`}
					style={{
						width: column.width,
						minWidth: column.width,
						left: column.fixed
							? `${columns.slice(0, columnIndex).reduce((acc, curr) => acc + (curr.width ?? 0), getInitialFixedLeftPos(useCheckBox))}px`
							: 'unset',
						zIndex: column.fixed ? 3 : 0,
					}}
					className={`table-head-cell bt_table_head__${column.key}${columnIndex === getLastIndexOfFixedColumn(columns) ? ' last_index' : ''}`}
				>
					{column.onSearch ? (
						<Popover
							position='bottomLeft'
							trigger={
								<span className='columnSearch'>
									<Text
										variant='body_small_bold'
										as='span'
									>
										{column.title}
									</Text>
									<SearchIcon />
								</span>
							}
							renderContent={
								<div className='columnSearchRender'>
									<SearchInput
										autoFocus
										placeholder={`Search ${typeof column.title === 'string' ? column.title : ''}`}
										size='small'
										onChange={handleTableColumnSearch(column.onSearch, column.key)}
									/>
								</div>
							}
						/>
					) : (
						<Text
							variant='body_small_bold'
							as='span'
						>
							{column.title}
						</Text>
					)}
				</th>
			))}
		</tr>
	);
};
