import { Text } from 'BreetComponents';
import type { RoutParamsType } from 'BreetConfig';
import { appConfig, sanitizeRouteParams } from 'BreetConfig';
import { useNavitemList } from 'BreetHooks';
import { Link, useLocation, useParams } from 'react-router-dom';

import { BreetLogo } from '@/assets/icons';

import CollapsibleNavItem from './CollapsibleNavItem';

const SideNavBar = () => {
	const navitemList = useNavitemList();
	const { pathname } = useLocation();
	const params = useParams<RoutParamsType>();

	const activeNavItemClass = (linkIncluded: boolean) => (linkIncluded ? ' active' : '');

	return (
		<div className='bt_layout_admin_sideBar'>
			<div className='sideBar_logo'>
				<Link to='/dashboard'>
					<BreetLogo type='whiteColor' />
				</Link>
			</div>
			<div className='sideBar_nav'>
				<ul className='sideBar_navlist'>
					{navitemList.map((navItem) => {
						if (navItem.key === 'appComponents' && !appConfig.isLocalMode) return null;
						if (navItem.linkRef === 'menu' && navItem.childRoutes)
							return (
								<li
									className={`sideBar_navlist_item${activeNavItemClass(navItem.activeState.map((route) => sanitizeRouteParams(route, params)).includes(pathname))}`}
									key={navItem.key}
								>
									<CollapsibleNavItem
										header={
											<div className='sideBar_navlist_item_link align-flex-center'>
												<div className='sideBar_navlist_item_ltr align-flex-center'>
													{navItem.linkIcon}
													<Text variant='body_small_bold'>{navItem.linkTitle}</Text>
												</div>
											</div>
										}
									>
										<div className='subNavList'>
											{navItem.childRoutes.map((childRoute) => (
												<Link
													key={childRoute.linkRef}
													to={childRoute.linkRef}
													className={`subNavList_item${activeNavItemClass(navItem.activeState.map((route) => sanitizeRouteParams(route, params)).includes(pathname) && pathname.startsWith(childRoute.linkRef))}`}
												>
													<Text variant='body_small_bold'>{childRoute.linkTitle}</Text>
												</Link>
											))}
										</div>
									</CollapsibleNavItem>
								</li>
							);

						return (
							<li
								className={`sideBar_navlist_item ${activeNavItemClass(navItem.activeState.map((route) => sanitizeRouteParams(route, params)).includes(pathname))}`}
								key={navItem.key}
							>
								<Link
									to={navItem.linkRef}
									className='sideBar_navlist_item_link align-flex-center'
								>
									<div className='sideBar_navlist_item_ltr align-flex-center'>
										{navItem.linkIcon}
										<Text variant='body_small_bold'>{navItem.linkTitle}</Text>
									</div>
								</Link>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default SideNavBar;
