import { matchStyle } from 'BreetHelpers';
import { useCallback, useEffect, useState } from 'react';

import type { IToggle } from './Toggle.helper';

const Toggle = ({
	checked,
	onToggleChange,
	onToggleClick,
	id = 'bt_toggle_switch_id',
	disabled,
	className,
	leftIcon,
	rightIcon,
	...rest
}: IToggle) => {
	const [toggleValue, setToggleValue] = useState<boolean>(false);

	useEffect(() => {
		if (checked !== toggleValue) setToggleValue(Boolean(checked));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked]);

	const toggleChangeHandler = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (disabled) return;
			setToggleValue(e.target.checked);
			if (onToggleChange) onToggleChange(e.target.checked);
		},
		[disabled, onToggleChange]
	);

	const toggleClickHandler = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			if (disabled) return;
			if (onToggleClick) onToggleClick(!toggleValue);
		},
		[disabled, onToggleClick, toggleValue]
	);

	const wrapClassName = matchStyle(className, {
		[`${className}`]: ` ${className}`,
		default: '',
	});

	return (
		<button
			type='button'
			className={`bt_toggle_switch${wrapClassName}`}
			onClick={toggleClickHandler}
			disabled={disabled}
		>
			<input
				{...rest}
				checked={toggleValue}
				onChange={toggleChangeHandler}
				className='bt_toggle_switch__checkbox'
				id={id}
				name={id}
				type='checkbox'
			/>
			<label
				className='bt_toggle_switch_label'
				htmlFor={id}
			>
				<span className='bt_toggle_switch_button' />
				{!!leftIcon && <span className='bt_toggle_switch_ltIcon'>{leftIcon}</span>}
				{!!rightIcon && <span className='bt_toggle_switch_rtIcon'>{rightIcon}</span>}
			</label>
		</button>
	);
};

export default Toggle;

Toggle.displayName = 'BreetToggle';
