import { useCallback, useEffect, useState } from 'react';

import { ActionIcon } from '@/assets/icons';

import { Button } from '../Button';
import { Popover } from '../Popover';
import { Text } from '../Text';
import type { ITableActionPopover } from './Table.helper';

const TableActionPopover = ({ actionItems, isLoading, triggerVariant = 'white' }: ITableActionPopover) => {
	const [clickedAction, setClickedAction] = useState('');

	useEffect(() => {
		if (!isLoading) setClickedAction('');
	}, [isLoading]);

	const handleActionClick = useCallback(
		(actionTitle: string, onClick?: () => void) => () => {
			if (isLoading) return;
			if (onClick) onClick();
			setClickedAction(actionTitle);
		},
		[isLoading]
	);

	return (
		<Popover
			position='bottomRight'
			trigger={
				<Button
					size='small'
					variant={triggerVariant}
				>
					<ActionIcon />
					&nbsp;&nbsp;Actions
				</Button>
			}
			className='tableActionRender'
			renderContent={
				<div className='tableActionRenderlist'>
					{actionItems.map((action) => (
						<button
							type='button'
							disabled={action.isLoading ?? isLoading}
							key={action.title.toLowerCase().replace(' ', '-')}
							className='tableActionRenderlist_item align-flex-center'
							onClick={handleActionClick(action.title, action.onClick)}
						>
							<Text variant='body_small_regular'>{action.title}</Text>
							{(clickedAction === action.title && action.isLoading) ?? (clickedAction === action.title && isLoading) ? (
								<div className='isloading' />
							) : null}
						</button>
					))}
				</div>
			}
		/>
	);
};

export default TableActionPopover;
