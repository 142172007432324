import type React from 'react';
import { useEffect } from 'react';

interface UseClickOutsideType {
	targetElement: React.RefObject<HTMLElement> | undefined;
	onClickOutsideClick?: () => void;
	onClickInsideClick?: () => void;
	dependencies?: unknown[];
}

/**
 * Custom hook for handling click events outside a specified target element.
 * or inside an element
 *
 * @param {Object} options - The hook options.
 * @param {RefObject<HTMLDivElement>} options.targetElement - The target element ref for which click events will be monitored.
 * @param {Function} [options.onClickOutsideClick] - Callback function invoked when a click occurs outside the target element.
 * @param {Function} [options.onClickInsideClick] - Callback function invoked when a click occurs inside the target element.
 * @param {Function} [options.dependencies] - Array of any type to update the function passed in the event listener for the target element.
 */
export const useClickOutside = ({
	targetElement,
	onClickOutsideClick,
	onClickInsideClick,
	dependencies,
}: UseClickOutsideType) => {
	// Event handler for mousedown events. Determines whether the click occurred inside or outside the target element.
	const clickOutside = (e: MouseEvent) => {
		if (targetElement?.current?.contains(e.target as Node)) {
			if (onClickInsideClick) onClickInsideClick();
			return;
		}
		if (onClickOutsideClick) onClickOutsideClick();
	};

	useEffect(() => {
		document.addEventListener('mousedown', clickOutside);
		return () => {
			document.removeEventListener('mousedown', clickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [targetElement, dependencies ?? null]);

	// This hook typically returns something related to the component using it.
	// You might want to return null or a callback if needed.
	return null;
};
