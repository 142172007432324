import { Button, TextareaInput } from 'BreetComponents';
import { closeModalById } from 'BreetConfig';
import type { IUserTagType } from 'BreetHelpers';
import { spawnAppToast } from 'BreetHelpers';
import { selectModals, useAppSelector, useTagUntagUserMutation } from 'BreetRedux';
import { useCallback } from 'react';

const RemoveProfileNote = () => {
	const [removeUserNote, { isLoading }] = useTagUntagUserMutation();
	const modals = useAppSelector(selectModals);

	const currentModal = modals.find((modal) => modal.id === 'removeProfileNote');
	const modalMetadata = currentModal?.metadata as (IUserTagType & { userId: string }) | undefined;

	const handleRemoveNoteClick = useCallback(() => {
		if (!modalMetadata) return;

		removeUserNote({ userId: modalMetadata.userId, tag: { note: '', status: false } })
			.unwrap()
			.then((resp) => {
				spawnAppToast({ message: resp.message, type: 'success' });
				closeModalById('removeProfileNote');
			})
			.catch((err: unknown) => {
				spawnAppToast({ dataMsg: err, type: 'error' });
			});
	}, [modalMetadata, removeUserNote]);

	return (
		<div className='modalFormWrapper'>
			<TextareaInput
				extValue={modalMetadata?.note}
				label='Note'
				placeholder='Note'
				disabled
			/>
			<Button
				size='large'
				variant='red'
				onClick={handleRemoveNoteClick}
				loading={isLoading}
			>
				Remove
			</Button>
		</div>
	);
};

export default RemoveProfileNote;
