import { Text } from 'BreetComponents';
import type { RoutParamsType } from 'BreetConfig';
import { sanitizeRouteParams, userMgtSubRouteList } from 'BreetConfig';
import { useGetSingleUserQuery } from 'BreetRedux';
import { Link, useLocation, useParams } from 'react-router-dom';

export const SideNavBar = () => {
	const { pathname } = useLocation();
	const params = useParams<RoutParamsType>();

	const { data, isLoading } = useGetSingleUserQuery(params.userId);

	const activeNavClass = (linkIncluded: boolean) => (linkIncluded ? ' active' : '');

	return (
		<div className='userMgtSubRouteList'>
			{userMgtSubRouteList.map((routeItem) => {
				if (
					(data?.data?.country === 'ghana' && routeItem.linkTitle === 'NGN Withdrawals') ||
					(isLoading && routeItem.linkTitle === 'NGN Withdrawals')
				)
					return null;
				if (
					(data?.data?.country === 'nigeria' && routeItem.linkTitle === 'GHS Withdrawals') ||
					(isLoading && routeItem.linkTitle === 'GHS Withdrawals')
				)
					return null;

				return (
					<Link
						key={routeItem.linkRef}
						to={sanitizeRouteParams(routeItem.linkRef, params)}
						className={`userMgtSubRouteList_item${activeNavClass(sanitizeRouteParams(routeItem.linkRef, params).includes(pathname))}`}
					>
						<Text variant='body_small_bold'>{routeItem.linkTitle}</Text>
					</Link>
				);
			})}
		</div>
	);
};
