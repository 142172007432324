import type { RouteObject } from 'react-router-dom';

const reportRoutes: RouteObject[] = [
	{
		path: 'reports',
		children: [
			{
				path: 'platform-metrics',
				lazy: () => import('../features/reports/platform-metrics'),
			},
			{
				path: 'other-metrics',
				lazy: () => import('../features/reports/other-metrics'),
			},
			{
				path: 'crypto-transactions',
				lazy: () => import('../features/reports/crypto-transactions'),
			},
			{
				path: 'payment-metrics',
				lazy: () => import('../features/reports/payment-metrics'),
			},
			{
				path: 'download-reports',
				lazy: () => import('../features/reports/download-reports'),
			},
		],
	},
];

export default reportRoutes;
