import type { TxStatusType, UserStausType } from '../types';
import { matchStyle } from '../typography';

export type StatusColorOptionType = 'success' | 'warning' | 'error' | 'alternate';

export const statusBgColorClassname = (
	status?: StatusColorOptionType | TxStatusType | UserStausType,
	defaultClassName?: string
) =>
	matchStyle(status, {
		success: ' status_successBg',
		completed: ' status_successBg',
		active: ' status_successBg',
		warning: ' status_warningBg',
		pending: ' status_warningBg',
		processing: ' status_processingBg',
		error: ' status_errorBg',
		failed: ' status_errorBg',
		deleted: ' status_errorBg',
		rejected: ' status_errorBg',
		inactive: ' status_errorBg',
		flagged: ' status_errorBg',
		banned: ' status_errorBg',
		alternate: ' status_alternateBg',
		reversed: ' status_alternateBg',
		default: defaultClassName ? ` ${defaultClassName}` : '',
	});
