import type { IApiPaginatedFilterQuery, IApiResponse, IApiResponsePaginated, IUserRewards } from 'BreetHelpers';

import { apiSlice } from './apiSlice';

export const rewardsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getUserRewards: builder.query<IApiResponsePaginated<IUserRewards[]>, IApiPaginatedFilterQuery & { userId?: string }>({
			query: ({ userId, ...params }) => ({ url: `/admin/payments/user/${userId}/rewards`, params }),
			providesTags: ['GetUserRewards'],
		}),
		getRewardDetails: builder.query<IApiResponse<IUserRewards>, string | undefined>({
			query: (txReference) => ({ url: `/admin/payments/reward/${txReference}` }),
		}),
	}),
});

export const { useGetUserRewardsQuery, useGetRewardDetailsQuery } = rewardsApiSlice;
