import { matchStyle } from 'BreetHelpers';
import React, { useCallback, useEffect, useState } from 'react';

import { Text } from '../Text';
import type { ITextareaInput } from './Input.heper';

const TextareaInput = ({
	id,
	wrapperClassName,
	onChange,
	extValue,
	placeholder,
	rows = 10,
	register,
	errorMsg,
	label,
	...rest
}: ITextareaInput) => {
	const [inputValue, setInputValue] = useState<string>('');

	const textareaInputId = placeholder?.split(' ').join('-');

	useEffect(() => {
		if (extValue !== undefined) setInputValue(extValue);
	}, [extValue]);

	const onTextInputChangeHandler = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			setInputValue(e.target.value);
			if (onChange) onChange(e.target.value);
			register?.onChange({ target: e.target, type: e.type }).catch((err: unknown) => {
				console.error(err);
			});
		},
		[onChange, register]
	);

	const wrapTextareaClassName = matchStyle(wrapperClassName, {
		[`${wrapperClassName}`]: ` ${wrapperClassName}`,
		default: '',
	});

	const errorMsgClassName = errorMsg ? ' errorInput' : '';

	return (
		<div className={`bt_input_contain_wrapper${errorMsgClassName}`}>
			{label ? (
				<Text
					variant='body_small_bold'
					className='bt_input_label'
				>
					{label}
				</Text>
			) : null}
			<div className={`bt_input_contain textarea ${wrapTextareaClassName}`}>
				<textarea
					id={id ?? textareaInputId}
					placeholder={placeholder}
					value={inputValue}
					rows={rows}
					{...register}
					onChange={onTextInputChangeHandler}
					{...rest}
				/>
			</div>
			{errorMsg ? (
				<Text
					className='bt_input_error'
					variant='body_small_bold'
					as='span'
				>
					{errorMsg}
				</Text>
			) : null}
		</div>
	);
};

export default TextareaInput;

TextareaInput.displaName = 'BreetTextareaInput';
